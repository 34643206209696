export function attachSearchParams(url, params = {}) {
  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));
  return url;
}

export function authHeader() {
  let token = localStorage.getItem('token');
  if (token) {
    return {
      Authorization: `Token ${token}`,
    };
  } else {
    return {};
  }
}
