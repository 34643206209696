// 动态设置font-size, 解决移动端适配问题
(function (doc, win) {
  const docEl = win.document.documentElement;
  const resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
  const refreshRem = function () {
    const clientWidth = win.innerWidth || doc.documentElement.clientWidth || doc.body.clientWidth;

    if (!clientWidth) return;
    const width = clientWidth;
    const fz = (16 * width) / 375;
    docEl.style.fontSize = `${fz}px`;
  };

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, refreshRem, false);
  doc.addEventListener('DOMContentLoaded', refreshRem, false);
  refreshRem();
})(document, window);
