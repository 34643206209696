import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, useHistory, Prompt } from 'react-router-dom';
import styled from 'styled-components';
import FancyRoute from 'components/FancyRoute';
import Tab from 'components/Tab';
import Divider from 'components/Divider';
import Arrow from 'components/Arrow';
import { useForm } from 'react-hook-form';
import * as dayjs from 'dayjs';
import * as api from 'api/index';
import { Calendar, Picker, Stepper, Checkbox, Toast, Modal } from 'antd-mobile';
import { useInterval } from 'hooks';
const AgreeItem = Checkbox.AgreeItem;

const BtnSubmit = styled.button`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.97rem;
  background-color: #8f5f34;
  color: white;
  font-size: 1.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  z-index: 100;
`;

const StyledCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0.94rem;
  font-size: 0.94rem;

  .label {
    color: #333;
    display: inline-block;
    width: 40%;
    ${(props) => (props.top ? 'align-self: flex-start;' : '')};
  }

  .value {
    color: ${(props) => (props.red ? '#f23030' : props.primary ? '#8f5f34' : '#333')};
    text-align: right;
    width: 100%;
    padding-right: 0.44rem;
    /* color: #cccccc; */
    position: relative;

    input {
      border: none;
      width: 100%;
      outline: none;
      font-size: 0.94rem;
      &::placeholder {
        color: #cccccc;
      }
    }
    position: relative;

    .error {
      position: absolute;
      top: 100%;
      left: 0;
      /* left: 0.2rem; */
      color: #f23030;
      font-size: 0.8rem;
    }
    .placeholder {
      color: #cccccc;
    }
  }
`;

const InfoModal = styled(Modal)`
  margin-top: -10%;
  width: 85%;
  /* height: 80%; */
  max-height: 80%;
  p {
    margin-bottom: 0.5rem;
  }
`;

function Cell(props) {
  return (
    <StyledCell {...props}>
      <span className="label">{props.label}</span>
      <div className="value">
        {props.children}
        {/* {props.error && <span className="error">{props.errorHint}</span>} */}
      </div>
      {props.arrow && <Arrow />}
    </StyledCell>
  );
}

const StyledForm = styled.form`
  background-color: white;
`;

const periodList = [
  {
    label: '9:00-10:00',
    value: '9:00-10:00',
  },
  {
    label: '10:00-11:00',
    value: '10:00-11:00',
  },
  {
    label: '11:00-12:00',
    value: '11:00-12:00',
  },
  {
    label: '12:00-13:00',
    value: '12:00-13:00',
  },
  {
    label: '13:00-14:00',
    value: '13:00-14:00',
  },
  {
    label: '14:00-15:00',
    value: '14:00-15:00',
  },
  {
    label: '15:00-16:15',
    value: '15:00-16:15',
  },
];

function getExtraKey(date) {
  return +new Date(date.getFullYear(), date.getMonth(), date.getDate());
}

function useCloseDate() {
  let [closeDate, setCloseDate] = useState({});
  useEffect(() => {
    api.fetchDisableDates().then((data) => {
      let temp = {};

      data.forEach((date) => {
        temp[getExtraKey(new Date(date))] = {
          info: '闭馆',
          disable: true,
        };
      });

      // 处理无效日期
      Object.keys(temp).forEach((key) => {
        const info = temp[key];
        const date = new Date(key);
        if (!Number.isNaN(+date) && !temp[+date]) {
          temp[+date] = info;
        }
      });

      setCloseDate(temp);
    });

    return () => {
      setCloseDate({});
    };
  }, []);

  return closeDate;
}

let defaultCalendar = new Date();
defaultCalendar.setHours(0);
defaultCalendar.setMinutes(0);
defaultCalendar.setSeconds(0);

const now = new Date();
const MODAL_DELAY = 10;

function Person() {
  let closeDate = useCloseDate();
  const { register, handleSubmit, errors } = useForm();
  let [showCalendar, setShowCalendar] = useState(false);
  let [showPeriod, setShowPeriod] = useState(false);
  let [num, setNum] = useState(0);
  let [date, setDate] = useState(0);
  let [period, setPeriod] = useState(0);
  let [hasRead, setHasRead] = useState(false);
  let [showNoteModal, setShowNoteModal] = useState(true);
  let history = useHistory();
  let [isBlock, setIsBlock] = useState(true);
  const foo = () => {};

  let [counter, setCounter] = useState(MODAL_DELAY);
  useInterval(() => setCounter(counter - 1), counter > 0 ? 1000 : null);

  useEffect(() => {
    if (counter === 0) {
      setShowNoteModal(false);
      setHasRead(true);
    }
  }, [counter]);

  const onCalendarConfirm = (date) => {
    console.log('date ', date);
    setShowCalendar(false);
    setDate(date);
  };

  const onSubmit = (data) => {
    console.log('@submit', data);
    if (!hasRead) {
      Toast.fail('请阅读预约须知之后再提交');
      return;
    }

    if (!date) {
      Toast.fail('请选择参观日期');
      return;
    }

    if (!period) {
      Toast.fail('请选择预约时间');
      return;
    }

    setIsBlock(false);

    Toast.loading('正在提交');
    const params = {
      type: 'personal', // personal/group
      date: new Date(date).toJSON(),
      period: period,
      children: num,
      ...data,
    };

    console.log('@submit params', params);
    api
      .createBooking(params)
      .then((res) => {
        Toast.success('预约成功', 1);
        setTimeout(() => {
          history.push('/');
        }, 1000);
      })
      .catch((err) => {
        Toast.fail('预约失败,请刷新后重试', 1);
      });
  };
  const onPeriodConfirm = (data) => {
    setPeriod(data[0]);
    setShowPeriod(false);
  };

  const showNote = (e) => {
    e.preventDefault();
    setShowNoteModal(true);
  };

  return (
    <div>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Divider />
        <Cell label="参观日期" arrow>
          <span
            onClick={() => {
              setShowCalendar(true);
            }}
          >
            {date ? (
              dayjs(date).format('YYYY-MM-DD')
            ) : (
              <span className="placeholder">请选择参观日期</span>
            )}
          </span>
        </Cell>
        <Cell label="参观时间" arrow>
          <span
            onClick={() => {
              setShowPeriod(true);
            }}
          >
            {period ? (
              periodList.find((item) => item.value === period).label
            ) : (
              <span className="placeholder">请选择参观时间</span>
            )}
          </span>
        </Cell>
        <Cell label="联系姓名" error={errors.name} errorHint="姓名不规范">
          <input
            name="name"
            type="text"
            placeholder="请输入联系姓名"
            // defaultValue="特没谱"
            ref={register({ minLength: 2, maxLength: 25, required: true })}
          />
          {errors.name?.type === 'required' && <span className="error">该项为必填项</span>}
          {errors.name?.type === 'minLength' && <span className="error">不能少于2个字符</span>}
          {errors.name?.type === 'maxLength' && <span className="error">不能超过25个字符</span>}
        </Cell>
        <Cell label="手机号码" error={errors.phone} errorHint="手机号码不规范">
          <input
            name="phone"
            type="number"
            placeholder="请输入手机号码"
            // defaultValue="18228281918"
            ref={register({
              required: true,
              minLength: 11,
              maxLength: 11,
              pattern: /^1[3-9]\d{9}$/,
            })}
          />
          {errors.phone?.type === 'required' && <span className="error">该项为必填项</span>}
          {errors.phone?.type === 'minLength' && <span className="error">请输入11个字符</span>}
          {errors.phone?.type === 'maxLength' && <span className="error">请输入11个字符</span>}
          {errors.phone?.type === 'pattern' && <span className="error">手机号格式有误</span>}
        </Cell>
        <Cell label="身份证号" error={errors.idNumber} errorHint="身份证号码不规范">
          <input
            name="idNumber"
            type="number"
            placeholder="请输入身份证号"
            // defaultValue="421223199009091818"
            ref={register({
              required: true,
              pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
            })}
          />
          {errors.idNumber?.type === 'required' && <span className="error">该项为必填项</span>}
          {errors.idNumber?.type === 'pattern' && <span className="error">身份证号格式有误</span>}
        </Cell>
        <Cell label="随行儿童">
          <Stepper
            style={{ width: '50%' }}
            showNumber
            max={10}
            min={0}
            value={num}
            onChange={(n) => {
              setNum(n);
            }}
          />
        </Cell>
        <Prompt
          when={isBlock}
          message={() => '跳转到新页面将丢失填写的表单数据, 您确定要跳转吗?'}
        />
        <Calendar
          type="one"
          visible={showCalendar}
          defaultTimeValue={defaultCalendar}
          onCancel={() => {
            setShowCalendar(false);
          }}
          onConfirm={(date) => {
            onCalendarConfirm(date);
          }}
          onSelectHasDisableDate={foo}
          getDateExtra={(date) => closeDate[+date]}
          minDate={new Date(+now + 86400000)}
          maxDate={new Date(+now + 633600000)}
        />

        <Picker
          visible={showPeriod}
          cols="1"
          data={periodList}
          title="请选择参观时间"
          onOk={onPeriodConfirm}
          onDismiss={() => {
            setShowPeriod(false);
          }}
        ></Picker>
        <BtnSubmit type="submit">提交预约</BtnSubmit>
      </StyledForm>
      <AgreeItem checked={hasRead} onChange={(e) => setHasRead(e.target.checked)}>
        <span style={{ color: '#8f5f34' }} onClick={showNote}>
          【梁书美术馆预约须知】
        </span>
      </AgreeItem>
      <InfoModal
        visible={showNoteModal}
        transparent
        animationType="slide-up"
        onClose={() => setShowNoteModal(false)}
        title={`梁书美术馆参观须知${counter !== 0 ? ` ${counter}秒后关闭` : ''}`}
        closable={counter === 0}
        maskClosable={counter === 0}
      >
        <div style={{ overflow: 'scroll', height: '90%', textAlign: 'left' }}>
          <p>1、梁书美术馆向社会公众预约开放，为保证参观质量，同一时间在馆人数不超过20人。</p>
          <p>2、梁书美术馆开放时间：9时30分至16时30分（16时15分停止入场），每周一闭馆。</p>
          <p>
            3、观众凭身份证等有效证件，网上实名制预约参观。12周岁以下儿童在其监护人的带领下进入馆内参观。
          </p>
          <p>4、现场不能预约当日参观，每日现场预约人数不超过20人。</p>
          <p>5、严禁将宠物、饮料、食品、易燃易爆等物品带入本馆。</p>
          <p>6、禁止携带大件提包、行李入内，禁止携带液体、水杯入内，现金及贵重物品自己妥善保管。</p>
          <p>7、美术馆内严禁吸烟、严禁拍照、严禁触摸藏品。</p>
          <p>8、请文明着装，勿穿拖鞋，背心入馆。入馆后需保持安静，遵守馆内秩序。</p>
          <p>9、爱护公共环境，请勿乱丢杂物，请勿随地吐痰。</p>
          <p>10、参观人员必须服从现场工作人员和安保人员的安排，如不服从，停止参观，劝离美术馆。</p>
        </div>
      </InfoModal>
    </div>
  );
}
function Group() {
  let closeDate = useCloseDate();
  const { register, handleSubmit, errors } = useForm();
  let [showCalendar, setShowCalendar] = useState(false);
  let [showPeriod, setShowPeriod] = useState(false);
  let [num, setNum] = useState(1);
  let [date, setDate] = useState(0);
  let [period, setPeriod] = useState(0);
  let [hasRead, setHasRead] = useState(false);
  let [showNoteModal, setShowNoteModal] = useState(true);
  let history = useHistory();
  let [isBlock, setIsBlock] = useState(true);
  const foo = () => {};

  let [counter, setCounter] = useState(MODAL_DELAY);
  useInterval(() => setCounter(counter - 1), counter > 0 ? 1000 : null);

  useEffect(() => {
    if (counter === 0) {
      setShowNoteModal(false);
      setHasRead(true);
    }
  }, [counter]);

  const onCalendarConfirm = (date) => {
    console.log('date ', date);
    setShowCalendar(false);
    setDate(date);
  };

  const onSubmit = (data) => {
    console.log('@submit', data);
    if (!hasRead) {
      Toast.fail('请阅读预约须知之后再提交');
      return;
    }

    if (!date) {
      Toast.fail('请选择参观日期');
      return;
    }

    if (!period) {
      Toast.fail('请选择预约时间');
      return;
    }

    setIsBlock(false);

    Toast.loading('正在提交');
    const params = {
      type: 'group',
      date: new Date(date).toJSON(),
      period: period,
      peopleNumber: num,
      ...data,
    };

    console.log('@submit params', params);
    api
      .createBooking(params)
      .then((res) => {
        Toast.success('预约成功', 1);
        setTimeout(() => {
          history.push('/');
        }, 1000);
      })
      .catch((err) => {
        Toast.fail('预约失败,请刷新后重试', 1);
      });
  };
  const onPeriodConfirm = (data) => {
    setPeriod(data[0]);
    setShowPeriod(false);
  };

  const showNote = (e) => {
    e.preventDefault();
    setShowNoteModal(true);
  };

  return (
    <div>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Divider />
        <Cell label="参观日期" arrow>
          <span
            onClick={() => {
              setShowCalendar(true);
            }}
          >
            {date ? (
              dayjs(date).format('YYYY-MM-DD')
            ) : (
              <span className="placeholder">请选择参观日期</span>
            )}
          </span>
        </Cell>
        <Cell label="参观时间" arrow>
          <span
            onClick={() => {
              setShowPeriod(true);
            }}
          >
            {period ? (
              periodList.find((item) => item.value === period).label
            ) : (
              <span className="placeholder">请选择参观时间</span>
            )}
          </span>
        </Cell>
        <Cell label="单位名称">
          <input
            name="companyName"
            type="text"
            placeholder="请输入单位名称"
            // defaultValue="宜春院"
            ref={register({ minLength: 2, maxLength: 25, required: true })}
          />
          {errors.companyName?.type === 'required' && <span className="error">该项为必填项</span>}
          {errors.companyName?.type === 'minLength' && (
            <span className="error">不能少于2个字符</span>
          )}
          {errors.companyName?.type === 'maxLength' && (
            <span className="error">不能超过25个字符</span>
          )}
        </Cell>
        <Cell label="联系姓名">
          <input
            name="name"
            type="text"
            placeholder="请输入联系姓名"
            // defaultValue="特没谱"
            ref={register({ minLength: 2, maxLength: 25, required: true })}
          />
          {errors.name?.type === 'required' && <span className="error">该项为必填项</span>}
          {errors.name?.type === 'minLength' && <span className="error">不能少于2个字符</span>}
          {errors.name?.type === 'maxLength' && <span className="error">不能超过25个字符</span>}
        </Cell>
        <Cell label="手机号码" error={errors.phone} errorHint="手机号码不规范">
          <input
            name="phone"
            type="number"
            placeholder="请输入手机号码"
            // defaultValue="18228281918"
            ref={register({
              required: true,
              minLength: 11,
              maxLength: 11,
              pattern: /^1[3-9]\d{9}$/,
            })}
          />
          {errors.phone?.type === 'required' && <span className="error">该项为必填项</span>}
          {errors.phone?.type === 'minLength' && <span className="error">请输入11个字符</span>}
          {errors.phone?.type === 'maxLength' && <span className="error">请输入11个字符</span>}
          {errors.phone?.type === 'pattern' && <span className="error">手机号格式有误</span>}
        </Cell>
        <Cell label="身份证号" error={errors.idNumber} errorHint="身份证号码不规范">
          <input
            name="idNumber"
            type="number"
            placeholder="请输入身份证号"
            // defaultValue="421223199009091818"
            ref={register({
              required: true,
              pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
            })}
          />
          {errors.idNumber?.type === 'required' && <span className="error">该项为必填项</span>}
          {errors.idNumber?.type === 'pattern' && <span className="error">身份证号格式有误</span>}
        </Cell>
        <Cell label="参观人数">
          <Stepper
            style={{ width: '50%' }}
            showNumber
            max={10}
            min={1}
            value={num}
            onChange={(n) => {
              setNum(n);
            }}
          />
        </Cell>
        <Prompt
          when={isBlock}
          message={() => '跳转到新页面将丢失填写的表单数据, 您确定要跳转吗?'}
        />
        <Calendar
          type="one"
          visible={showCalendar}
          onCancel={() => {
            setShowCalendar(false);
          }}
          onConfirm={(date) => {
            onCalendarConfirm(date);
          }}
          onSelectHasDisableDate={foo}
          getDateExtra={(date) => closeDate[+date]}
          minDate={new Date(+now + 86400000)}
          maxDate={new Date(+now + 633600000)}
        />

        <Picker
          visible={showPeriod}
          cols="1"
          data={periodList}
          title="请选择参观时间"
          onOk={onPeriodConfirm}
          onDismiss={() => {
            setShowPeriod(false);
          }}
        ></Picker>
        <BtnSubmit type="submit">提交预约</BtnSubmit>
      </StyledForm>
      <AgreeItem checked={hasRead} onChange={(e) => setHasRead(e.target.checked)}>
        <span style={{ color: '#8f5f34' }} onClick={showNote}>
          【梁书美术馆预约须知】
        </span>
      </AgreeItem>
      <InfoModal
        visible={showNoteModal}
        transparent
        animationType="slide-up"
        onClose={() => setShowNoteModal(false)}
        title={`梁书美术馆参观须知${counter !== 0 ? ` ${counter}秒后关闭` : ''}`}
        closable={counter === 0}
        maskClosable={counter === 0}
      >
        <div style={{ overflow: 'scroll', height: '90%', textAlign: 'left' }}>
          <p>1、梁书美术馆向社会公众预约开放，为保证参观质量，同一时间在馆人数不超过20人。</p>
          <p>2、梁书美术馆开放时间：9时30分至16时30分（16时15分停止入场），每周一闭馆。</p>
          <p>
            3、观众凭身份证等有效证件，网上实名制预约参观。12周岁以下儿童在其监护人的带领下进入馆内参观。
          </p>
          <p>4、现场不能预约当日参观，每日现场预约人数不超过20人。</p>
          <p>5、严禁将宠物、饮料、食品、易燃易爆等物品带入本馆。</p>
          <p>6、禁止携带大件提包、行李入内，禁止携带液体、水杯入内，现金及贵重物品自己妥善保管。</p>
          <p>7、美术馆内严禁吸烟、严禁拍照、严禁触摸藏品。</p>
          <p>8、请文明着装，勿穿拖鞋，背心入馆。入馆后需保持安静，遵守馆内秩序。</p>
          <p>9、爱护公共环境，请勿乱丢杂物，请勿随地吐痰。</p>
          <p>10、参观人员必须服从现场工作人员和安保人员的安排，如不服从，停止参观，劝离美术馆。</p>
        </div>
      </InfoModal>
    </div>
  );
}
function Index({ routes }) {
  const list = [
    { label: '个人预约', to: '/booking/person' },
    { label: '团体预约', to: '/booking/group' },
  ];
  return (
    <div>
      <Tab list={list} />
      <Switch>
        <Route exact path="/booking">
          <Redirect to="/booking/person"></Redirect>
        </Route>
        {routes.map((route, index) => (
          <FancyRoute key={index} {...route} />
        ))}
      </Switch>
    </div>
  );
}

export default Index;
export { Index, Person, Group };
