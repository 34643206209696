import baseUrl from './config';
import { authHeader, attachSearchParams } from './utils';

const instance = {
  get(url, params) {
    url = attachSearchParams(new URL(`${baseUrl}${url}`), params);
    return fetch(url, {
      headers: authHeader(),
    });
  },
  post(url, params) {
    return fetch(`${baseUrl}${url}`, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: {
        'Content-type': 'application/json; charset=utf-8',
        ...authHeader(),
      },
    });
  },
  patch(url, params) {
    return fetch(`${baseUrl}${url}`, {
      method: 'PATCH',
      body: JSON.stringify(params),
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        ...authHeader(),
      },
    });
  },
};

export default instance;
