import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from '../../routes';
import FancyRoute from '../../components/FancyRoute';
import { ProvideAuth } from '../../hooks/useAuth';
// import './style.scss';

const App = () => {
  return (
    <ProvideAuth>
      <Router>
        <Switch>
          {routes.map((route, index) => (
            <FancyRoute key={index} {...route} />
          ))}
        </Switch>
      </Router>
    </ProvideAuth>
  );
};

export default App;
