import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import * as api from '../../api';

const ArtList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ArtItem = styled(Link)`
  width: 11.66rem;
  height: 11.66rem;
  display: block;
  background: url(${(props) => props.cover || require('../../assets/images/sample_arts_cover.jpg')})
    no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 0.13rem;

  .title {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;

    font-size: 0.88rem;
    line-height: 1;
    color: white;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 0.34rem 0.94rem;
  }
`;

function List({ list }) {
  return (
    <ArtList>
      {list.map((item, index) => {
        return (
          <ArtItem key={index} to={`/art/${item.id}`} cover={item.thumbnailUrl}>
            <span className="title">{item.title}</span>
          </ArtItem>
        );
      })}
    </ArtList>
  );
}

const FilterItem = styled.span`
  font-size: 0.94rem;
  line-height: 1;
  padding: 1.13rem 0.94rem;
  color: ${(props) => (props.selected ? '#8f5f34' : '#666666')};
  transition: color 0.2s;

  white-space: nowrap;
`;

const FilterBar = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
`;

function generateTypeList(list) {
  let res = {};
  list
    .map((item) => item.type)
    .forEach((item) => {
      res[item.id] = item;
    });

  let arr = Object.values(res);
  arr.unshift({ id: -1, name: '全部' });

  return arr;
}

function Arts() {
  let [fullList, setFullList] = useState([]);
  let [list, setList] = useState([]);
  let [current, setCurrent] = useState(-1);
  let [typeList, setTypeList] = useState([]);

  useEffect(() => {
    api.fetchArts().then((data) => {
      console.log(data);

      setList(data);
      setFullList(data);
      setTypeList(generateTypeList(data));
    });

    return () => {
      setFullList([]);
      setList([]);
      setTypeList([{ id: -1, name: '全部' }]);
    };
  }, []);

  const handleSelect = (id) => {
    let newList = id === -1 ? fullList : fullList.filter((item) => item.type.id === id);
    setCurrent(id);
    setList(newList);
  };

  return (
    <div>
      <FilterBar>
        {typeList.map((item, index) => {
          return (
            <FilterItem
              key={index}
              selected={item.id === current}
              onClick={() => {
                handleSelect(item.id);
              }}
            >
              {item.name}
            </FilterItem>
          );
        })}
      </FilterBar>
      <List list={list} />
    </div>
  );
}

export default Arts;
