import React from 'react';
import Home from './views/Home';
import News from './views/News';
import Arts from './views/Arts';
import WebView from './views/WebView';
import AuthHandler from './views/AuthHandler';
import AddComment from './views/AddComment';
import NewsDetail from './views/NewsDetail';
import { About, Person, Hall } from './views/About';
import { Guide, Notice, Line, Traffic } from './views/Guide';
import * as Comments from './views/Comment';
import * as Art from './views/ArtDetail';
import * as BookingList from './views/BookingList';
import * as BookingDetail from './views/BookingDetail';
import * as Booking from './views/BookingForm';
import * as Check from './views/Check';

const routes = [
  {
    title: '',
    path: '/',
    exact: true,
    component: Home,
  },
  {
    path: '/about',
    component: About,
    routes: [
      {
        title: '美术馆简介',
        path: '/about/hall',
        component: Hall,
      },
      {
        title: '梁书简介',
        path: '/about/person',
        component: Person,
      },
    ],
  },
  {
    path: '/guide',
    component: Guide,
    title: '参观指引',
    routes: [
      {
        path: '/guide/notice',
        component: Notice,
      },
      {
        path: '/guide/line',
        component: Line,
      },
      {
        path: '/guide/traffic',
        component: Traffic,
      },
    ],
  },
  {
    title: '参观预约',
    path: '/booking',
    component: Booking.Index,
    routes: [
      {
        path: '/booking/person',
        component: Booking.Person,
        // 如果子路由需要校验权限, 应该把auth放到子路由; 如果放到父路由, 会导致重复执行登录逻辑, 因为进入子路由要加载父路由, 所以实际上父路由执行了2次
        auth: true,
      },
      {
        path: '/booking/group',
        component: Booking.Group,
        auth: true,
      },
    ],
  },
  {
    title: '我要留言',
    path: '/comments/add',
    auth: true,
    component: AddComment,
  },
  {
    title: '参观留言',
    path: '/comments',
    component: Comments.Index,
    routes: [
      {
        title: '参观留言',
        path: '/comments/all',
        component: Comments.All,
      },
      {
        title: '我的留言',
        path: '/comments/user',
        component: Comments.My,
        auth: true,
      },
    ],
  },
  {
    title: '美术馆藏品',
    path: '/arts',
    component: Arts,
  },
  {
    title: '',
    path: '/art/:id/origin',
    component: Art.Origin,
  },
  {
    title: '',
    path: '/art/:id',
    component: Art.Detail,
  },
  {
    title: '公告资讯',
    path: '/news/:id',
    component: NewsDetail,
  },
  {
    title: '公告资讯',
    path: '/news',
    component: News,
  },
  {
    title: '预约详情',
    path: '/user/booking/:id',
    component: BookingDetail.MyDetail,
  },
  {
    title: '我的预约',
    path: '/user/booking',
    component: BookingList.User,
    auth: true,
  },
  {
    title: '详情',
    path: '/admin/booking/:id',
    component: BookingDetail.AdminDetail,
    auth: true,
    role: 'admin',
  },
  {
    title: '预约审核',
    path: '/admin/booking',
    component: BookingList.Admin,
    auth: true,
    role: 'admin',
  },
  {
    title: '检票',
    path: '/admin/check/input',
    component: Check.Input,
    auth: true,
    role: 'admin',
  },
  // FIXME: 临时方法, 后期需优化Url
  {
    title: '详情',
    path: '/admin/check/:id',
    component: BookingDetail.AdminDetail,
    auth: true,
    role: 'admin',
  },
  {
    title: '活动检票',
    path: '/admin/check',
    component: Check.Index,
    auth: true,
    role: 'admin',
  },
  {
    title: '跳转中...',
    path: '/redirect',
    component: WebView,
  },
  {
    title: '登录中...',
    path: '/wxAuthHandler',
    component: AuthHandler,
  },

  {
    title: '404',
    path: '*',
    component: () => <span>404</span>,
  },
];

export default routes;
