import React, { useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { useHistory } from 'react-router-dom';
import { Toast } from 'antd-mobile';
import styled from 'styled-components';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 1rem;
  color: #333;
`;

function Index() {
  const auth = useAuth();
  const history = useHistory();
  const query = new URLSearchParams(window.location.search);
  const returnUrl = query.get('returnUrl') ? query.get('returnUrl') : '/';
  const code = query.get('code');

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      // 已经登录过的用户, 直接返回首页, 因为没法获取之前的之前的页面
      if (auth.user) {
        history.replace('/');
      } else {
        // 未登录, 并且没有code, 先通过微信授权获取code
        if (!code) {
          // const appid = 'wx96fb5552d2ef5323';
          const appid = 'wxfd8913842b3e0ac4';
          const redirectUrl = encodeURIComponent('https://liangshu.idea888.com/wxAuthHandler');
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${redirectUrl}&response_type=code&scope=snsapi_base&state=${returnUrl}#wechat_redirect`;
        } else {
          // 通过code登录
          const state = query.get('state');

          Toast.loading('正在登录');
          auth
            .login(code)
            .then(() => {
              Toast.success('登录成功', 1);
              setTimeout(() => {
                history.push(state);
              }, 1000);
            })
            .catch((err) => {
              Toast.hide();
            });
        }
      }
    } else {
      // 本地测试

      auth.login('234234').then(() => {
        setTimeout(() => {
          history.push('/');
        }, 1000);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Container>请稍等...</Container>;
}

export default Index;
