import http from './http';

export const createRecord = (params) => {
  return http.post('/records', params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};
