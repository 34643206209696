import http from './http';

export const fetchWxSdkConfig = (url) => {
  return http.get(`/validation?url=${url}`).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};
