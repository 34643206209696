import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import styles from './style.module.scss';
import * as dayjs from 'dayjs';
import * as api from '../../api';
import { Modal, Toast } from 'antd-mobile';

const statusText = (status) => {
  switch (status) {
    case 'pending':
      return '待审核';
    case 'verified':
      return '待检票';
    case 'checked':
      return '已检票';
    case 'rejected':
      return '审核未通过';
    default:
      return '待审核';
  }
};

const StyledBookingItem = styled(Link)`
  width: 21.56rem;
  height: 8.12rem;
  margin: 0 auto 1.25rem;
  display: block;

  background: url(${require('../../assets/images/booking_item_bg.png')}) no-repeat;
  background-size: cover;
`;

const StyledBookingList = styled.div`
  background-color: #f2f2f2;

  padding-top: 1.25rem;
`;

function BookingItem({ booking, to, showCheckBtn }) {
  const check = (e) => {
    e.preventDefault();

    Modal.alert('提示', '你确定要通过通过审核吗?', [
      {
        text: '拒绝',
        onPress: () => {
          Toast.loading('请稍等');
          api.updateBooking(booking.id, { status: 'rejected' }).then((res) => {
            Toast.success('操作成功');
            // 刷新状态
            // window.location.reload()
          });
        },
      },
      {
        text: '通过',
        onPress: () => {
          Toast.loading('请稍等');
          api.updateBooking(booking.id, { status: 'verified' }).then((res) => {
            Toast.success('操作成功');
            // 刷新状态
            // window.location.reload()
          });
        },
      },
    ]);
  };

  return (
    <StyledBookingItem className={styles.bookingItem} to={to}>
      <section className={styles.top}>
        <span className={styles.time}>
          {dayjs(booking.date).format('YYYY-MM-DD')} {booking.period}
        </span>
        {showCheckBtn && booking.status === 'pending' ? (
          <div className={styles.btnCheck} onClick={check}>
            审核
          </div>
        ) : (
          <span className={`${styles.status} ${booking.status === 'rejected' ? styles.red : ''}`}>
            {statusText(booking.status)}
          </span>
        )}
      </section>
      <section className={styles.bottom}>
        <p className={styles.name}>{booking.name}</p>
        <div className={styles.footer}>
          <span className={styles.phone}>{booking.phone}</span>
          <span className={styles.type}>
            {booking.type === 'personal' ? '个人预约' : '团体预约'}
          </span>
        </div>
      </section>
      <img
        hidden={booking.status !== 'checked'}
        className={styles.imgChecked}
        src={require('../../assets/images/icon_ticket_checked.png')}
        alt="checked"
      />
    </StyledBookingItem>
  );
}

export function BookingList({ list, admin, showCheckBtn }) {
  return (
    <StyledBookingList>
      {list.map((item, index) => (
        <BookingItem
          key={index}
          booking={item}
          to={admin ? `/admin/booking/${item.id}` : `/user/booking/${item.id}`}
          showCheckBtn={showCheckBtn}
        />
      ))}
    </StyledBookingList>
  );
}

export function useBookingList() {
  const [list, setList] = useState([]);
  const [isInit, setIsInit] = useState(false);

  useEffect(() => {
    api.fetchBookings().then((res) => {
      setList(res);
      setIsInit(true);
    });

    return () => {
      setList([]);
    };
  }, []);

  return { list, isInit };
}
