import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import Divider from 'components/Divider';
import FancyRoute from 'components/FancyRoute';
import Tab from 'components/Tab';
import { Toast } from 'antd-mobile';
import { useAuth } from 'hooks/useAuth';
import { useHistory, Link, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import * as dayjs from 'dayjs';
import * as api from 'api';

const StyledReply = styled.section`
  background-color: #f2f2f2;
  border-radius: 0.19rem;
  padding: 0.81rem 0.59rem;
  margin-top: 0.94rem;

  header {
    img {
      width: 0.81rem;
      height: 0.81rem;
      vertical-align: middle;
    }
    span {
      font-size: 0.88rem;
      line-height: 1;
      color: #666666;
      vertical-align: middle;
    }
  }
  .content {
    margin: 0.56rem 0 0;
    font-size: 0.88rem;
    line-height: 1.13rem;
    color: #666666;

    &.fold {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  overflow: hidden;
  button {
    margin-top: 0.3rem;
    float: right;
    outline: none;
    border: none;
    font-size: 0.88rem;
    color: #333333;
    background-color: transparent;
  }
`;

function Reply({ content }) {
  let [isExpand, setIsExpand] = useState(false);
  return (
    <StyledReply>
      <header>
        <img src={require('../../assets/images/icon_reply.png')} alt="icon" />
        &nbsp;
        <span>馆回复</span>
      </header>
      <p className={`content ${isExpand ? '' : 'fold'}`}>{content}</p>
      {(content.length * 0.88) / 20 > 2 && (
        <button onClick={() => setIsExpand(true)} hidden={isExpand}>
          展开
        </button>
      )}
    </StyledReply>
  );
}

const CommentItem = styled.section`
  margin: 1.56rem 0 1.88rem;
  padding: 0 0.94rem;

  header {
    width: 100%;
    display: flex;
    align-items: flex-end;
    .status {
      font-size: 0.94rem;
      line-height: 1;
      color: #8f5f34;
    }

    .name {
      font-size: 0.94rem;
      line-height: 1;
      color: #333333;
    }
    .date {
      font-size: 0.75rem;
      line-height: 1;
      color: #999999;
    }
    .vote-up {
    }
  }
  .content {
    .text {
      margin: 0.73rem 0;
      font-size: 0.88rem;
      line-height: 1.31rem;
      color: #666666;
    }

    .img-list {
      display: flex;
      justify-content: space-between;
      img {
        /* width: 50%; */
        /* height: 50%; */
        width: 10.62rem;
        height: 10.62rem;
      }

      img:first-child {
        margin-right: 0.28rem;
      }
    }
  }
  footer {
    margin-top: 0.64rem;
    img {
      width: 0.93rem;
      height: 0.93rem;
      vertical-align: middle;
    }
    span {
      font-size: 0.75rem;
      line-height: 1;
      color: #999999;
      vertical-align: middle;
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 0 1rem;
  background-color: white;
  overflow-y: scroll;
  height: calc(100vh - 3.28rem);
  /* border-top: solid #f2f2f2 0.47rem; */
  /* height: calc(100vh - 2.81rem); */
`;

const StyledBtnVote = styled.div`
  margin-left: auto;
  color: ${(props) => (props.active ? '#8f5f34' : '#999999')};
  .num {
    font-size: 0.75rem;
    line-height: 1;
    vertical-align: bottom;
  }

  img {
    width: 1.06rem;
    height: 0.96rem;
    vertical-align: bottom;
  }
`;

function BtnVote({ num, active, onClick }) {
  return (
    <StyledBtnVote active={active} onClick={onClick}>
      <img
        src={
          active
            ? require('../../assets/images/icon_vote_active.png')
            : require('../../assets/images/icon_vote.png')
        }
        alt="icon"
      />
      &nbsp;
      <span className="num">{num}</span>
    </StyledBtnVote>
  );
}

function CommentList({ list, onVote, hideName, hideScope, hideStatus }) {
  const auth = useAuth();
  const history = useHistory();
  const { pathname } = useLocation();

  const toggleVote = (comment) => {
    // “待审核"和”审核不通过“的留言，不能点赞
    if (['待审核', '审核不通过'].includes(comment.status)) {
      return;
    }

    if (!auth.user) {
      history.replace(`/wxAuthHandler?returnUrl=${pathname}`);
      return;
    }

    let params = {
      voteUp: !comment.voteUp,
    };

    Toast.loading('请稍等');
    api.updateComment(comment.id, params).then((res) => {
      onVote && onVote(res);
      Toast.hide();
    });
  };
  return (
    <Wrapper>
      {list.map((item, index) => {
        return (
          <Fragment>
            {index === 0 && <Divider />}
            <CommentItem key={index}>
              <header>
                <span hidden={hideStatus} className="status">
                  {item.status}
                </span>
                <span hidden={hideName} className="name">
                  游客
                </span>
                &nbsp;
                <span className="date">{dayjs(item.date).format('YYYY-MM-DD')}</span>
                <BtnVote
                  onClick={() => {
                    toggleVote(item);
                  }}
                  num={item.votes}
                  active={item.voteUp}
                />
              </header>
              <section className="content">
                <p className="text">{item.content}</p>
                {!!item.images.length && (
                  <div className="img-list">
                    {item.images.map((src, i) => (
                      <img key={i} src={src} alt="pic" />
                    ))}
                  </div>
                )}
              </section>
              <footer hidden={hideScope} className="scope">
                <img
                  src={require(`../../assets/images/icon_${
                    item.public ? 'public' : 'private'
                  }.png`)}
                  alt="icon"
                />
                &nbsp;
                <span>公开留言</span>
              </footer>
              {item.reply && <Reply content={item.reply} />}
            </CommentItem>
          </Fragment>
        );
      })}
    </Wrapper>
  );
}

function AllComments() {
  let [list, setList] = useState([]);

  useEffect(() => {
    api.fetchComments().then((res) => {
      setList(res);
    });

    return () => setList([]);
  }, []);

  const onVote = (comment) => {
    let cp = [...list];
    let index = list.findIndex((item) => item.id === comment.id);
    cp[index] = comment;
    setList(cp);
  };

  return <CommentList list={list} onVote={onVote} hideStatus hideScope></CommentList>;
}

function MyComments(params) {
  let [list, setList] = useState([]);

  useEffect(() => {
    api.fetchMyComments().then((res) => {
      // TODO: test
      // api.fetchComments().then((res) => {
      setList(res);
    });

    return () => setList([]);
  }, []);

  const onVote = (comment) => {
    let cp = [...list];
    let index = list.findIndex((item) => item.id === comment.id);
    cp[index] = comment;
    setList(cp);
  };

  return <CommentList list={list} onVote={onVote} hideName></CommentList>;
}

const CommentLink = styled(Link)`
  position: fixed;
  right: 1rem;
  bottom: 30vh;
  z-index: 100;

  img {
    width: 3.56rem;
    height: 3.62rem;
  }
`;

function Comments({ routes }) {
  const tabList = [
    { label: '全部留言', to: '/comments/all' },
    { label: '我的留言', to: '/comments/user' },
  ];

  return (
    <div style={{ minHeight: '100vh' }}>
      <Tab list={tabList} />
      <Switch>
        <Route exact path="/comments">
          <Redirect to="/comments/all"></Redirect>
        </Route>
        {routes.map((route, index) => (
          <FancyRoute key={index} {...route} />
        ))}
      </Switch>
      <CommentLink to="/comments/add">
        <img src={require('../../assets/images/icon_comment.png')} alt="icon" />
      </CommentLink>
    </div>
  );
}

export default Comments;
export { Comments as Index, MyComments as My, AllComments as All };
