import React, { Fragment, useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import { Modal } from 'antd-mobile';
import './FancyRoute.scss';

nprogress.configure({ showSpinner: false });

const FancyRoute = (route) => {
  let history = useHistory();
  // console.log('watching href => ', window.location.href);
  // console.log('watching route => ', route);

  useEffect(() => {
    document.title = route.title || '梁书美术馆';
    nprogress.done();

    return function cleanup() {
      nprogress.start();
      document.title = '梁书美术馆';
    };
  }, [route.title]);

  const auth = useAuth();
  const { pathname } = useLocation();
  if (route.auth && !auth.user) {
    return (
      <Modal
        visible
        transparent
        maskClosable={false}
        title="提示"
        footer={[
          {
            text: '去登陆',
            onPress: () => {
              history.replace(`/wxAuthHandler?returnUrl=${pathname}`);
            },
          },
        ]}
      >
        您尚未登录
        <br />
        登录之后才能正常使用此功能
      </Modal>
    );
  }

  if (route.role && route.role !== auth.user.role) {
    return (
      <Modal
        visible
        transparent
        maskClosable={false}
        title="提示"
        footer={[
          {
            text: '确定',
            onPress: () => {
              history.push('/');
            },
          },
        ]}
      >
        您没有权限使用此功能
        <br />
        请联系后台管理员
      </Modal>
    );
  }

  return (
    <Fragment>
      <Route
        path={route.path}
        render={(props) => {
          return <route.component routes={route.routes} {...props} />;
        }}
      />
    </Fragment>
  );
};

export default FancyRoute;
