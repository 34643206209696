import http from './http';


export const createComment = (params) => {
  return http.post('/comments', params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};

export const fetchComments = (params) => {
  return http.get('/comments', params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};

export const fetchMyComments = (params) => {
  return http.get('/users/comments', params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};

export const updateComment = (id, params) => {
  return http.patch(`/comments/${id}`, params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};
