import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import FancyRoute from 'components/FancyRoute';
import Tab from 'components/Tab';
import * as api from 'api';

const PersonContainer = styled.div`
  height: 100vh;
  width: 100vw;
  background: url(${require('../../assets/images/about_person.jpg')}) no-repeat;
  background-size: cover;
  padding: 3rem 0 0;

  .wrapper {
    padding: 0 0.94rem;
  }
`;

const Title = styled.h6`
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 1.88rem;
  color: #8f5f34;
  margin: 0 0 0.94rem;
  span {
    font-weight: normal;
    font-size: 0.94rem;
    line-height: 1.88rem;
    color: #8f5f34;
  }
`;

const Paragraph = styled.p`
  font-size: 0.88rem;
  line-height: 1.38rem;
  color: #4d4d4d;
  text-indent: 2em;
  margin: 0;
`;

/**
 *
 * @param {string} type 简介类型
 */

function useAbout(type) {
  let [html, setHtml] = useState('');

  useEffect(() => {
    api.fetchAbout(type).then((res) => {
      setHtml(res.richtext);
    });

    return () => {
      setHtml('');
    };
  }, [type]);

  return html;
}

function Hall() {
  let html = useAbout('hall');
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
  // return <img src={require('../../assets/images/about_hall.jpg')} alt="pic" />;
}

function Person() {
  let html = useAbout('person');
  return <div dangerouslySetInnerHTML={{ __html: html }}></div>;
  // return (
  //   <PersonContainer>
  //     <div className="wrapper">
  //       <Title>
  //         梁书<span>（1905-1998）</span>
  //       </Title>
  //       <Paragraph>
  //         字凯世，号惕生，江西省万安县罗塘乡双龙村人，中国近现代著名画家，国画大师。
  //       </Paragraph>
  //       <Paragraph>
  //         江西近代著名国画家，一生淡泊明志，专注国画艺术创作，师古而不仿古，艺术上追求突破创新，成就其粗犷雄浑、气韵生动的个人风格；梁书一生热爱祖国，热爱家乡，关心艺术教育，对爱情忠贞不渝。
  //       </Paragraph>
  //     </div>
  //   </PersonContainer>
  // );
}

function About({ routes }) {
  const list = [
    { label: '美术馆简介', to: '/about/hall' },
    { label: '梁书简介', to: '/about/person' },
  ];
  return (
    <div>
      <Tab list={list} />
      <Switch>
        <Route exact path="/about">
          <Redirect to="/about/hall"></Redirect>
        </Route>
        {routes.map((route, index) => (
          <FancyRoute key={index} {...route} />
        ))}
      </Switch>
    </div>
  );
}

export { About, Person, Hall };
export default About;
