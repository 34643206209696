import React, { useState, useEffect, useContext, createContext } from 'react';
import * as api from '../api';

const authContext = createContext();
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [user, setUser] = useState(null);

  // 应用打开时初始化用户数据
  useEffect(() => {
    refresh();

    return () => {
      setUser(false);
    };
  }, []);

  const login = (code) => {
    // Toast.loading('正在登录');
    return api
      .login({ code })
      .then((res) => {
        // Toast.success('登录成功', 1);
        setUser(res.user);
        localStorage.setItem('token', res.user.token);
      })
      .catch((err) => {
        return Promise.reject(err);
        // Toast.fail('登录失败,请刷新后重试', 1);
      });
  };

  // 根据本地token拉取用户信息, 更新react状态
  const refresh = () => {
    api
      .getUser()
      .then((res) => {
        setUser(res.user);
        localStorage.setItem('token', res.user.token);
      })
      .catch((err) => {
        console.log('Auto login fail');
      });
  };

  return {
    user,
    login,
    refresh,
  };
}
