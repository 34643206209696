import React from 'react';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';
import FancyRoute from '../../components/FancyRoute';
import Tab from '../../components/Tab';
import Divider from '../../components/Divider';

const Layout = styled.div`
  background: url(${require('../../assets/images/guide_bg.jpg')}) no-repeat;
  background-size: cover;
  min-height: 100vh;
`;

const Wrapper = styled.div`
  padding-left: 0.94rem;
  padding-right: 0.94rem;
  padding-top: ${(props) => props.paddingTop || '1.07rem'};
`;

const Paragraph = styled.p`
  font-size: 0.88rem;
  line-height: 1.38rem;
  color: #4d4d4d;
  /* text-indent: 2em; */
  margin: 0 0 0.59rem;
`;

const BtnBooking = styled(Link)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  background-color: #975d2a;
  color: white;
  font-size: 1.13rem;
  line-height: 1;
  padding: 0.91rem 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

function Notice() {
  return (
    <Layout style={{ paddingBottom: '2.95rem' }}>
      <img src={require('../../assets/images/guide_notice_banner.jpg')} alt="banner" />
      <Wrapper>
        <Paragraph>
          1、梁书美术馆向社会公众预约开放，为保证参观质量，同一时间在馆人数不超过20人。
        </Paragraph>
        <Paragraph>
          2、梁书美术馆开放时间：9时30分至16时30分（16时15分停止入场），每周一闭馆。
        </Paragraph>
        <Paragraph>
          3、观众凭身份证等有效证件，网上实名制预约参观。12周岁以下儿童在其监护人的带领下进入馆内参观。
        </Paragraph>
        <Paragraph>4、现场不能预约当日参观，每日现场预约人数不超过20 人。</Paragraph>
        <Paragraph>5、严禁将宠物、饮料、食品、易燃易爆等物品带入本馆。</Paragraph>
        <Paragraph>
          6、禁止携带大件提包、行李入内，禁止携带液体、水杯 入内，现金及贵重物品自己妥善保管。
        </Paragraph>
        <Paragraph>７、美术馆内严禁吸烟、严禁拍照、严禁触摸藏品。</Paragraph>
        <Paragraph>8、请文明着装，勿穿拖鞋，背心入馆。入馆后需保持安 静，遵守馆内秩序。</Paragraph>
        <Paragraph>9、爱护公共环境，请勿乱丢杂物，请勿随地吐痰。</Paragraph>
        <Paragraph>
          10、参观人员必须服从现场工作人员和安保人员的安排， 如不服从，停止参观，劝离美术馆。
        </Paragraph>
      </Wrapper>
      <BtnBooking to="/booking">已阅读 立即预约</BtnBooking>
    </Layout>
  );
}

const LinePic1 = styled.img`
  display: block;
  margin-bottom: 1.38rem;
`;

function Line() {
  return (
    <div>
      <Divider />
      <Layout>
        <Wrapper paddingTop="1.59rem">
          <LinePic1 src={require('../../assets/images/guide_line_1.png')} alt="pic" />
          <img src={require('../../assets/images/guide_line_2.png')} alt="pic" />
        </Wrapper>
      </Layout>
    </div>
  );
}

const TrafficText = styled.p`
  font-size: 0.94rem;
  color: #333333;
  margin: 0 0 0.3rem;

  span {
    color: #8f5f34;
  }
`;

function Traffic(params) {
  return (
    <Layout>
      <img src={require('../../assets/images/guide_traffic_map.jpg')} alt="map" />
      <Wrapper paddingTop="1.56rem">
        <TrafficText>
          <span>地址：</span>南昌市东湖区苏圃路八一公园内
        </TrafficText>
        <TrafficText>
          <span>公交：</span>
          18路内线；25路快班；27路；33路；205路；227路；227路长班;230路；830路至八一公园站下车，步行300米即可到达
        </TrafficText>
      </Wrapper>
    </Layout>
  );
}

function Guide({ routes }) {
  const list = [
    { label: '参观须知', to: '/guide/notice' },
    { label: '参观路线', to: '/guide/line' },
    { label: '交通指引', to: '/guide/traffic' },
  ];
  return (
    <div>
      <Tab list={list} />
      <Switch>
        <Route exact path="/guide">
          <Redirect to="/guide/notice"></Redirect>
        </Route>
        {routes.map((route, index) => (
          <FancyRoute key={index} {...route} />
        ))}
      </Switch>
    </div>
  );
}

export { Guide, Line, Traffic, Notice };
export default Guide;
