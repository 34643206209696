import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const TabItem = styled(NavLink)`
  font-size: 0.94rem;
  line-height: 1;
  color: #666666;
  &.active {
    color: #8f5f34;
  }
`;

const StyledTab = styled.div`
  display: flex;
  align-items: center;
  background-color: white;

  a {
    flex: 1;
    padding: 0.94rem 0;
    text-align: center;
    position: relative;

    &::after {
      content: '';
      height: 0.94rem;
      background-color: #ccc;
      width: 0.03rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate3d(0, -50%, 0);
    }
    &:last-child::after {
      display: none;
    }
  }
`;

function Tab({ list }) {
  return (
    <StyledTab>
      {list.map((item, index) => (
        <TabItem key={index} to={item.to} exact={item.exact} replace>
          {item.label}
        </TabItem>
      ))}
    </StyledTab>
  );
}

export { Tab, TabItem };
export default Tab;
