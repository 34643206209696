import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
`;

function WebView(props) {
  console.log('props ', props);
  console.log('location ', useLocation());
  console.log('ref ', document.referrer);
  console.log('host ', document.location.host);
  const query = useQuery();
  // let isSameSite = document.referrer.includes(document.location.host);
  // if (isSameSite) {
  //   document.location.href = query.get('url');
  // }

  document.location.href = query.get('url');
  return (
    <Wrapper
      onClick={() => {
        document.location.href = query.get('url');
      }}
    >
      Loading...
    </Wrapper>
  );
}

export default WebView;
