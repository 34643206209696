import http from './http';

export const fetchArts = (params) => {
  return http.get('/arts', params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};

export const fetchArt = ({ id, ...params }) => {
  return http.get(`/arts/${id}`, params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};
