import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Arrow from 'components/Arrow';
import Divider from 'components/Divider';
import styles from './style.module.scss';
import * as api from 'api/index';
import * as hooks from 'hooks';
import { useAuth } from 'hooks/useAuth';

function Notice() {
  let list = hooks.useNews();
  let [current, setCurrent] = useState(0);

  useEffect(() => {
    let interval = null;

    interval = setInterval(() => {
      setCurrent((n) => (n + 1) % list.length);
    }, 2000);

    return () => {
      clearInterval(interval);
      setCurrent(0);
    };
  }, [list.length]);

  return (
    <div className={styles.notice}>
      <img
        className={styles.pic}
        src={require('../../assets/images/home_announce_text.png')}
        alt="text"
      />
      <span className={styles.splitLine}>&nbsp;|&nbsp;</span>
      <div className={styles.content}>
        {list.map((item, index) => (
          <Link
            to={`/news/${item.id}`}
            className={`${styles.contentItem} ${index === current ? styles.current : ''} ${
              index === current - 1 ? styles.last : ''
            } ${index === current + 1 ? styles.next : ''}`}
            key={index}
          >
            {item.title}
            {/* <span title={item.title}>{item.title}</span> */}
          </Link>
        ))}
        {/* FIX 父元素无计算出高度 */}
        <span className={styles.placeholder}>placeholder</span>
      </div>
      <Link className={styles.btnNewsMore} to="/news">
        <Arrow color="#8f5f34" size="0.9rem" />
      </Link>
    </div>
  );
}

function Banner() {
  return (
    <div className={styles.banner}>
      <img src={require('../../assets/images/home_banner.jpg')} alt="banner" />
      <Notice />
    </div>
  );
}

function Grid() {
  const auth = useAuth();
  const list = [
    { name: '展馆简介', to: '/about' },
    { name: '展馆藏品', to: '/arts' },
    { name: '参观预约', to: '/booking' },
    { name: '参观指引', to: '/guide' },
    { name: '在线参观', to: '/redirect?url=https://external.333f.com/online-museum/#/' },
    { name: '语音导览', to: '/redirect?url=https://external.333f.com/ls-audio-guide/' },
    { name: '参观留言', to: '/comments' },
    { name: '预约记录', to: '/user/booking' },
    { name: '预约审核', to: '/admin/booking', admin: true },
    { name: '活动检票', to: '/admin/check', admin: true },
  ];

  return (
    <div className={styles.grid}>
      {list.map((item, index) => {
        if ((item.admin && !auth.user) || (item.admin && auth.user && auth.user.role !== 'admin')) {
          return null;
        }

        return (
          <Link className={styles.item} to={item.to} key={index}>
            <img src={require(`../../assets/images/home_grid_item_${index + 1}.png`)} alt="icon" />
            <span>{item.name}</span>
          </Link>
        );
      })}
    </div>
  );
}

const ListLeading = () => (
  <div className={styles.listLeading}>
    <span className={styles.title}>藏品鉴赏</span>
    <img
      className={styles.slogan}
      src={require('../../assets/images/home_list_leading_text.png')}
      alt="leading"
    />
    <Link className={styles.btnMore} to="/arts">
      更多
      <Arrow size="0.65rem" />
    </Link>
  </div>
);

function List() {
  let [list, setList] = useState([]);

  useEffect(() => {
    api.fetchArts({ position: 'home' }).then((data) => {
      setList(data);
    });

    return () => {
      setList([]);
    };
  }, []);

  return (
    <div className={styles.list}>
      <ListLeading />
      <div className={styles.listContent}>
        {list.map((item, index) => (
          <Link className={styles.listItem} to={`/art/${item.id}`} key={index}>
            <img src={item.thumbnailUrl} alt="cover" />
          </Link>
        ))}
      </div>
    </div>
  );
}

export default function Home() {
  return (
    <div className={styles.home}>
      <Banner />
      <Divider />
      <Grid />
      <Divider />
      <List />
    </div>
  );
}
