import React, { useState, useEffect, Fragment } from 'react';
import { BookingList, useBookingList } from './BookList';
import * as api from '../../api';
import styled from 'styled-components';
import { Calendar } from 'antd-mobile';
import * as dayjs from 'dayjs';
import { Link } from 'react-router-dom';

const NoBooking = styled.div`
  padding-top: 4.44rem;
  img {
    display: block;
    margin: 0 auto;
    width: 6.87rem;
    height: 5.03rem;
  }
  p {
    text-align: center;
    margin: 0.88rem 0 2.25rem;
    font-size: 0.94rem;
    line-height: 1;
    color: #999999;
  }
  a {
    width: 21.56rem;
    height: 2.5rem;
    background-color: #8f5f34;
    border-radius: 1.25rem;

    font-size: 1.13rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    margin: 0 auto;
  }
`;

function UserBookingList() {
  const { list, isInit } = useBookingList();

  if (!isInit) {
    return null;
  }
  if (isInit && list.length === 0) {
    return (
      <NoBooking>
        <img src={require('../../assets/images/nobooking.png')} alt="pic" />
        <p>您暂时没有预约哦~</p>
        <Link to="/booking">去预约</Link>
      </NoBooking>
    );
  }
  return <BookingList list={list} />;
}

const Tab = styled.span`
  line-height: 1;
  font-size: 0.94rem;
  white-space: nowrap;
  color: ${(props) => (props.active ? '#8f5f34' : '#666666')};

  display: flex;
  align-items: center;
`;

const AdminNav = styled.nav`
  height: 3.13rem;
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0.94rem;
  .tabs {
    height: 100%;
    width: 60%;
    display: flex;
    justify-content: space-between;
  }
  .date {
    height: 100%;
    display: flex;
    align-items: center;

    span {
      line-height: 1;
      font-size: 0.88rem;
      color: #999999;
      vertical-align: middle;
    }
    .icon {
      margin-right: 0.47rem;
      width: 0.87rem;
      height: 0.87rem;
      display: inline-block;
      vertical-align: middle;
    }
  }
`;

const tabs = [
  { label: '待审核 ', status: 'pending' },
  { label: '待检票', status: 'verified' },
  { label: '未通过', status: 'rejected' },
];

function AdminBookingList() {
  let [list, setList] = useState([]);
  let [fullList, setFullList] = useState([]);
  let [current, setCurrent] = useState(0);
  let [showCalendar, setShowCalendar] = useState(false);
  let [date, setDate] = useState(new Date());

  useEffect(() => {
    // TODO: replace in production
    api.fetchBookings({ select: 'all' }).then((res) => {
      // api.fetchBookings().then((res) => {
      setFullList(res);
    });

    return () => {
      setFullList([]);
    };
  }, []);

  useEffect(() => {
    let newList = fullList.filter(
      (item) =>
        item.status === tabs[current].status &&
        dayjs(new Date(item.date)).isSame(dayjs(new Date(date)), 'date')
    );
    setList(newList);
  }, [current, date, fullList]);

  const onClickTab = (index) => {
    if (current === index) {
      return;
    }
    setCurrent(index);
  };
  const onClickDate = () => {
    setShowCalendar(true);
  };
  const onCalendarConfirm = (date) => {
    setShowCalendar(false);
    setDate(date);
  };

  return (
    <Fragment>
      <AdminNav>
        <div className="tabs">
          {tabs.map((item, index) => {
            return (
              <Tab
                key={index}
                active={current === index}
                onClick={() => {
                  onClickTab(index);
                }}
              >
                {item.label}
              </Tab>
            );
          })}
        </div>
        <span style={{ color: '#cccccc' }}>|</span>
        <div className="date" onClick={onClickDate}>
          <img className="icon" src={require('../../assets/images/icon_calendar.png')} alt="icon" />
          <span>{dayjs(date).format('YYYY-MM-DD')}</span>
        </div>
      </AdminNav>
      {list.length > 0 ? (
        <BookingList list={list} showCheckBtn admin />
      ) : (
        <NoBooking>
          <img src={require('../../assets/images/nobooking.png')} alt="pic" />
          <p>您暂时没有预约哦~</p>
        </NoBooking>
      )}

      <Calendar
        type="one"
        visible={showCalendar}
        onCancel={() => {
          setShowCalendar(false);
        }}
        onConfirm={(date) => {
          onCalendarConfirm(date);
        }}
        // onSelectHasDisableDate={foo}
        // getDateExtra={(date) => closeDate[+date]}
      />
    </Fragment>
  );
}

export default UserBookingList;
export { UserBookingList as User, AdminBookingList as Admin, BookingList, useBookingList };
