import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Checkbox, Toast } from 'antd-mobile';
import { useForm } from 'react-hook-form';
import { Gallery, GalleryDelete, Uploader } from 'react-weui';
import 'weui';
import 'react-weui/build/packages/react-weui.css';
import * as api from 'api';
const AgreeItem = Checkbox.AgreeItem;

const BtnSubmit = styled.button`
  width: 100%;
  height: 2.97rem;
  color: white;
  font-size: 1.13rem;
  background-color: #8f5f34;
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;
  border: none;
  outline: none;
`;

const Wrapper = styled.div`
  min-height: 100vh;
  background-color: #f2f2f2;
`;

const Paper = styled.div`
  background-color: white;
  padding: 1.56rem 0.94rem;
  position: relative;
`;

const Textarea = styled.textarea`
  width: 100%;
  border: none;
  resize: none;
  font-size: 0.94rem;
  height: 8rem;
  margin-bottom: 1.5rem;

  &::placeholder {
    color: ${(props) => (props.error ? 'red' : '#ccc')};
  }
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > span {
    font-size: 0.81rem;
    color: #999999;
  }
`;

const MyAgreeItem = styled(AgreeItem)`
  margin-left: 0;
`;
function Index() {
  const { register, handleSubmit, errors } = useForm();
  let [files, setFiles] = useState([]);
  let [isPublic, setIsPublic] = useState(false);
  let history = useHistory();

  const onSubmit = (data) => {
    const images = files.map((item) => item.url);

    Toast.loading('请稍等', 30);
    api
      .createComment({
        public: isPublic,
        images,
        ...data,
      })
      .then((res) => {
        Toast.success('留言成功', 1);
        setTimeout(() => {
          history.replace('/comments/user');
        }, 1000);
      })
      .catch((err) => {
        Toast.fail('留言失败', 1);
      });
  };

  useEffect(() => {
    // Fix weui默认设置从摄像头获取上传图片的BUG
    document.getElementsByClassName('weui-uploader__input')[0].removeAttribute('capture');
  }, []);

  useEffect(() => {
    // Fix weui达到最大数量之后还显示上传按钮
    document.getElementsByClassName('weui-uploader__input-box')[0].hidden = files.length === 2;
  }, [files]);

  let [gallery, setGallery] = useState(false);

  const MyGallery = () => {
    return (
      <Gallery
        src={files.map((file) => file.url)}
        show
        defaultIndex={gallery.id}
        onClick={(e) => {
          //avoid click background item
          e.preventDefault();
          e.stopPropagation();
          setGallery(false);
        }}
      >
        <GalleryDelete
          onClick={(e, id) => {
            console.log('watching ', id);
            setFiles(files.filter((e, i) => i != id));
            setGallery(files.length <= 1 ? true : false);
          }}
        />
      </Gallery>
    );
  };

  return (
    <Wrapper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper>
          {gallery && <MyGallery />}
          <Textarea
            name="content"
            error={errors.content?.type === 'required'}
            placeholder="如果您对我们的展览、设施、服务有什么意见，或在参观的过程中有什么有趣的经历想和我们分享，请写在这里，非常感谢！"
            ref={register({ required: true })}
          ></Textarea>
          <Uploader
            title="上传图片"
            maxCount={2}
            files={files}
            onError={(msg) => console.error(msg)}
            onChange={(file, e) => {
              let newFiles = [...files, { url: file.data }];
              setFiles(newFiles);
            }}
            onFileClick={(e, file, i) => {
              console.log('file click', file, i);
              setGallery({
                url: file.url,
                id: i,
              });
            }}
          />
          <Bottom>
            <MyAgreeItem onChange={(e) => setIsPublic(e.target.checked)}>
              <span style={{ color: '#333', fontSize: '0.81rem' }}>公开留言</span>
            </MyAgreeItem>
            <span>公开留言大家可以浏览/点赞我的留言</span>
          </Bottom>
        </Paper>
        <BtnSubmit type="submit">发布留言</BtnSubmit>
      </form>
    </Wrapper>
  );
}

export default Index;
