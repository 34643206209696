import http from './http';

export const fetchDisableDates = () => {
  return http.get('/closeDate').then((res) => res.json());
};

export const createBooking = (params) => {
  return http.post('/bookings', params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};

export const fetchBookings = (params) => {
  return http.get('/bookings', params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};

export const fetchBooking = (id) => {
  return http.get(`/bookings/${id}`).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};

export const updateBooking = (id, params) => {
  return http.patch(`/bookings/${id}`, params).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};
