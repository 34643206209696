import React from 'react';
import { Link } from 'react-router-dom';
import * as dayjs from 'dayjs';
import * as hooks from 'hooks';
import styles from './style.module.scss';

function NewsItem(props) {
  return (
    <Link
      className={styles.newsItem}
      style={{ flexDirection: props.reverse ? 'row-reverse' : 'row' }}
      to={`/news/${props.id}`}
    >
      <div className={styles.cover}>
        <img src={props.coverUrl} alt="cover" />
      </div>
      <div className={styles.info}>
        <div className={styles.infoWrapper}>
          <h6 className={styles.tilte}>{props.title}</h6>
          <p className={styles.description}>{props.description}</p>
          <div className={styles.bottom}>
            <span className={styles.date}>{dayjs(new Date(props.date)).format('YYYY-MM-DD')}</span>
            <div className={styles.iconArrow}>
              <img src={require('../../assets/images/icon_news_arrow_right.png')} alt="icon" />
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default function News() {
  let list = hooks.useNews();

  return (
    <div className={styles.newsList}>
      {list.map((item, index) => (
        <NewsItem key={index} reverse={index % 2} {...item}></NewsItem>
      ))}
    </div>
  );
}
