import styled from 'styled-components';
import { ReactComponent as Arrow } from './right-arrow-angle.svg';

const CustomArrow = styled(Arrow).attrs((props) => ({
  color: props.color || '#cccccc',
  size: props.size || '1rem',
}))`
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  fill: ${(props) => props.color};
`;

export default CustomArrow;
