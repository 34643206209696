import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled, { keyframes, css } from 'styled-components';
import * as api from '../../api';

const beat = keyframes`
  from {
    transform:  scale(1);
  }

  to {
    transform:  scale(0.85);
  }
`;

const jump = keyframes`
  from {
    transform:  translate3d(-50%, 0.5rem, 0);
  }

  to {
    transform:  translate3d(-50%, -50%, 0);
  }
`;

const Prompt = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  z-index: 100;

  font-size: 0.88rem;
  line-height: 1;
  color: white;

  /* padding: 0.22rem 1rem; */
  padding: 0.4rem 1rem;
  background: rgba(0, 0, 0, 0.4);

  border-radius: 1rem;

  display: flex;
  align-items: center;
  ${(props) =>
    props.jump &&
    css`
      animation: ${jump} 0.8s linear infinite alternate;
    `}

  .icon {
    width: 1.25rem;
    height: 1.68rem;
    display: block;
    margin-right: 0.25rem;
    ${(props) =>
      props.beat &&
      css`
        animation: ${beat} 0.5s linear infinite alternate;
      `}
    transform-origin: bottom;
  }
`;

const Wrapper = styled.div`
  font-size: 0;
`;

function Detail() {
  const [art, setArt] = useState({});
  const { id } = useParams();

  useEffect(() => {
    api.fetchArt({ id }).then((data) => {
      setArt(data);
    });

    return () => {
      setArt({});
    };
  }, [id]);

  return (
    <Wrapper>
      <Link to={`/art/${id}/origin`}>
        <Prompt beat>
          <img
            className="icon"
            src={require('../../assets/images/icon_art_detail_hand.png')}
            alt="icon"
          />
          点击查看全图
        </Prompt>
      </Link>
      <img src={art.posterUrl} alt="detail" />
    </Wrapper>
  );
}
function Origin() {
  const [art, setArt] = useState({});
  const { id } = useParams();
  let [show, setShow] = useState(true);

  useEffect(() => {
    api.fetchArt({ id }).then((data) => {
      setArt(data);
      document.title = `《${data.title}》`;
    });

    api.createRecord({ type: 'art', id }).catch((err) => {
      console.log('打点失败');
    });

    return () => {
      setArt({});
    };
  }, [id]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (show) {
        setShow(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div onClick={() => setShow(false)}>
      <img src={art.originUrl} alt="pic" />
      {show && (
        <Prompt jump>
          <img
            className="icon"
            src={require('../../assets/images/icon_art_detail_hand.png')}
            alt="icon"
          />
          滑动查看全图
        </Prompt>
      )}
    </div>
  );
}

export { Detail, Origin };
