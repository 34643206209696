import http from './http';

export const fetchSingleNews = (id) => {
  return http.get(`/news/${id}`).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return Promise.reject({ text: res.statusText, code: res.status });
  });
};

export const fetchNews = (params) => {
  return http.get('/news', params).then((res) => res.json());
};
