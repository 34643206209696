import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Modal, Toast } from 'antd-mobile';
import * as api from '../../api';
import Arrow from '../../components/Arrow';
import { BookingList } from '../BookingList';
import { Calendar } from 'antd-mobile';
import * as dayjs from 'dayjs';
import wx from 'weixin-js-sdk';

const Logo = styled.img`
  width: 5rem;
  height: 5rem;
  display: block;
  margin: 0 auto;
`;

const IndexHeader = styled.header`
  padding-top: 1.56rem;
  .date {
    font-size: 0.94rem;
    color: #333333;
    line-height: 1;
    margin: 0.78rem 0;
    text-align: center;
  }
  .sum {
    margin: 0;
    text-align: center;

    font-size: 0.88rem;
    line-height: 1;
    color: #333333;
  }
  .link {
    width: 21.56rem;
    height: 2.5rem;
    background-color: #8f5f34;
    border-radius: 1.25rem;

    font-size: 1.13rem;
    color: white;
    line-height: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 1.25rem auto;
  }
`;

const IndexWrapper = styled.div`
  background-color: #f2f2f2;
`;

const Index = () => {
  let [showCalendar, setShowCalendar] = useState(false);
  let [date, setDate] = useState(new Date());
  let [fullList, setFullList] = useState([]);
  let [list, setList] = useState([]);
  let [sum, setSum] = useState(0);

  const onClickDate = () => {
    setShowCalendar(true);
  };
  const onCalendarConfirm = (date) => {
    setShowCalendar(false);
    setDate(date);
  };

  useEffect(() => {
    // TODO: replace in production
    api.fetchBookings({ status: 'checked', select: 'all' }).then((res) => {
      // api.fetchBookings({ status: 'checked',  }).then((res) => {
      setFullList(res);
    });

    return () => {
      setFullList([]);
    };
  }, []);

  useEffect(() => {
    let newList = fullList.filter((item) =>
      dayjs(new Date(item.date)).isSame(dayjs(new Date(date)), 'date')
    );

    setList(newList);

    let n = newList.reduce((a, cur) => {
      a += cur.type === 'personal' ? 1 : cur.peopleNumber;
      return a;
    }, 0);
    setSum(n);
  }, [date, fullList]);

  return (
    <IndexWrapper>
      <IndexHeader>
        <Logo src={require('../../assets/images/logo.png')} alt="logo" />
        <p className="date" onClick={onClickDate}>
          {dayjs(date).format('YYYY-MM-DD')}&nbsp;
          <Arrow size="0.72rem" />
        </p>
        <p className="sum">检票人数：{sum}人</p>
        {/* <Link className="link" to="/admin/check/input">
          检票
        </Link> */}
        {/* fix ios 不能检测到url变化, 导jssdk注册失败 */}
        <div
          className="link"
          onClick={() => {
            window.location.href = './check/input';
          }}
        >
          检票
        </div>
      </IndexHeader>
      <BookingList list={list} admin />

      <Calendar
        type="one"
        visible={showCalendar}
        onCancel={() => {
          setShowCalendar(false);
        }}
        onConfirm={(date) => {
          onCalendarConfirm(date);
        }}
        // onSelectHasDisableDate={foo}
        // getDateExtra={(date) => closeDate[+date]}
      />
    </IndexWrapper>
  );
};

const StyledCheckInput = styled.div`
  background-color: white;
  min-height: 100vh;
  padding-top: 2.47rem;
  .logo {
  }
  input {
    width: 21.56rem;
    height: 2.81rem;
    border-radius: 1.41rem;
    border: solid 0.03rem #e5e5e5;
    box-sizing: border-box;
    display: block;
    margin: 1.28rem auto 1.5rem;

    padding: 0.94rem 1.28rem;
    &::placeholder {
      font-size: 0.94rem;
      color: #999999;
    }
  }
  button {
    width: 21.56rem;
    height: 2.5rem;
    background-color: #8f5f34;
    border-radius: 1.25rem;

    font-size: 1.13rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    display: block;
    margin: 0 auto;
  }
  .hint {
    text-align: center;
    font-size: 0.88rem;
    color: #999999;
    margin: 1.28rem 0 0;
    line-height: 1;

    span {
      color: #8f5f34;
    }
  }
`;

const Input = () => {
  const [code, setCode] = useState('');
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    const url = window.location.href;

    api.fetchWxSdkConfig(url).then((res) => {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: res.appId, // 必填，企业号的唯一标识，此处填写企业号
        timestamp: res.timestamp, // 必填，生成签名的时间戳
        nonceStr: res.nonceStr, // 必填，生成签名的随机串
        signature: res.signature, // 必填，签名，见附录1
        jsApiList: ['scanQRCode'], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      });
    });

    wx.ready(() => {
      console.log('@config configWxJsSdk, wxJsSdk config success!');
      setIsReady(true);

      wx.checkJsApi({
        jsApiList: ['scanQRCode'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {
          console.log('@checkJsApi ', res);
        },
      });
    });
    wx.error(function (res) {
      // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      console.log('@config error ', res);
    });
  }, []);

  const onClickHint = () => {
    if (!isReady) {
      console.log('unready');
      return;
    }

    wx.scanQRCode({
      desc: 'scanQRCode desc',
      needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ['qrCode'], // 可以指定扫二维码还是一维码，默认二者都有
      success(res) {
        console.log('@scanQRCode, scan success and res is ', res);
        Toast.clear();
      },
      error(res) {
        Toast.clear();
        if (res.errMsg.indexOf('function_not_exist') > 0) {
          alert('版本过低请升级');
        }
      },
    });
  };

  const check = () => {
    if (!code) {
      Toast.fail('预约码不能为空');
      return;
    }

    api.fetchBookings({ code }).then((res) => {
      if (!res.length) {
        Toast.fail('该预约记录不存在');
        return;
      }

      let id = res[0].id;
      Modal.alert('提示', '你确定要通过通过检票吗?', [
        { text: '取消' },
        {
          text: '确定',
          onPress: () => {
            Toast.loading('请稍等');
            api.updateBooking(id, { status: 'checked' }).then((res) => {
              Toast.success('操作成功');
              setCode('');
            });
          },
        },
      ]);
    });
  };
  return (
    <StyledCheckInput>
      <Logo src={require('../../assets/images/logo.png')} alt="logo" />
      <input
        type="text"
        placeholder="请输入参观预约码"
        value={code}
        onChange={(e) => setCode(e.target.value)}
      />
      <button onClick={check}>确认检票</button>
      <p className="hint">
        输入太慢了？试下
        <span onClick={onClickHint}>扫一扫</span>
      </p>
    </StyledCheckInput>
  );
};

export { Index, Input };
