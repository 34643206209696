import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import * as api from 'api';

function Detail() {
  const { id } = useParams();
  let [html, setHtml] = useState('');

  useEffect(() => {
    api.fetchSingleNews(id).then((res) => setHtml(res.content));
    api.createRecord({ type: 'news', id }).catch((err) => {
      console.log('打点失败');
    });
  }, [id]);

  return <div style={{ padding: '0 0.94rem' }} dangerouslySetInnerHTML={{ __html: html }}></div>;
}

export default Detail;
