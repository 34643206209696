import { useEffect, useState } from 'react';
import * as api from 'api';

function useNews() {
  let [list, setList] = useState([]);

  useEffect(() => {
    api.fetchNews().then((data) => {
      setList(data);
    });

    return () => {
      setList([]);
    };
  }, []);

  return list;
}

export { useNews };
