import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode.react';
import { useParams, Link } from 'react-router-dom';
import * as dayjs from 'dayjs';
import { useAuth } from '../../hooks/useAuth';
import { Modal, Toast } from 'antd-mobile';

import * as api from '../../api';

const StyledCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 0.94rem;
  font-size: 0.94rem;

  .label {
    color: #999999;
    display: inline-block;
    width: 30%;
    ${(props) => (props.top ? 'align-self: flex-start;' : '')};
  }

  .value {
    color: ${(props) => (props.red ? '#f23030' : props.primary ? '#8f5f34' : '#333')};
    text-align: right;
  }
`;

function Cell(props) {
  return (
    <StyledCell {...props}>
      <span className="label">{props.label}</span>
      <span className="value">{props.value}</span>
    </StyledCell>
  );
}

const LeadingContainer = styled.div`
  margin: 0 0.94rem;
  text-align: center;
  padding: 2.8rem 0 1.5rem;

  border-bottom: dotted 0.06rem #ebebeb;
`;

const LeadingIcon = styled.img`
  width: 6.56rem;
  height: 6.56rem;
`;

const LeadingQrCode = styled.div`
  position: relative;
  text-align: center;

  .pic {
    width: 8.18rem;
    height: 8.18rem;
    display: block;
    margin: 0 auto;
  }
  .num {
    font-size: 1.25rem;
    color: #8f5f34;
    font-weight: bold;
    display: block;
  }
  .mark {
    width: 3.96rem;
    height: 3.96rem;
    position: absolute;
    z-index: 1;
    bottom: 0;
    right: 5rem;
  }
`;

function DetailLeading({ booking }) {
  if (booking.status === 'pending' || booking.status === 'rejected') {
    return (
      <LeadingContainer>
        <LeadingIcon
          src={require(`../../assets/images/icon_booking_detail_${booking.status}.png`)}
          alt="icon"
        />
      </LeadingContainer>
    );
  }

  if (booking.status === 'verified' || booking.status === 'checked') {
    return (
      <LeadingContainer>
        <LeadingQrCode>
          <QRCode value={`http://${window.location.host}/admin/booking/${booking.id}`} />
          <span className="num">{booking.code}</span>
          <img
            hidden={booking.status === 'verified'}
            className="mark"
            src={require(`../../assets/images/icon_ticket_checked.png`)}
            alt="icon"
          />
        </LeadingQrCode>
      </LeadingContainer>
    );
  }

  return null;
}

const statusText = (status) => {
  switch (status) {
    case 'pending':
      return '待审核';
    case 'verified':
      return '待检票';
    case 'checked':
      return '已检票';
    case 'rejected':
      return '审核未通过';
    default:
      return '待审核';
  }
};

function Detail({ booking }) {
  return (
    <div>
      <DetailLeading booking={booking} />
      <section>
        <Cell label="参观人数" value={booking.type === 'personal' ? '个人预约' : '团体预约'} />
        <Cell
          label="参观日期"
          value={`${dayjs(booking.date).format('YYYY-MM-DD')} ${booking.period}`}
        />
        {booking.type === 'group' && <Cell label="单位名称" value={booking.companyName} />}

        <Cell label="联系姓名" value={booking.name} />
        <Cell label="手机号码" value={booking.phone} />
        <Cell label="身份证号" value={booking.idNumber} />
        {booking.type === 'group' ? (
          <Cell label="参观人数" value={booking.peopleNumber} />
        ) : (
          <Cell label="随行儿童" value={booking.children} />
        )}
        <Cell label="提交时间" value={`${dayjs(booking.createdAt).format('YYYY-MM-DD HH:mm')}`} />
        <Cell
          label="预约状态"
          value={statusText(booking.status)}
          primary
          red={booking.status === 'rejected'}
        />
        {booking.status === 'rejected' && (
          <Cell label="原因" value="您的参观预约申请未通过，如有疑问请在公众号留言咨询" top />
        )}
        {['verified', 'checked', 'rejected'].includes(booking.status) && (
          <Cell label="审核时间" value={dayjs(booking.verifiedAt).format('YYYY-MM-DD HH:mm')} />
        )}
        {booking.status === 'checked' && (
          <Cell label="检票时间" value={dayjs(booking.checkedAt).format('YYYY-MM-DD HH:mm')} />
        )}
      </section>
    </div>
  );
}

function useBooking(id) {
  let [booking, setBooking] = useState({});

  useEffect(() => {
    api.fetchBooking(id).then((res) => {
      setBooking(res);

      return () => {
        setBooking({});
      };
    });
  }, [id]);

  return [booking, setBooking];
}

const BtnBottom = styled.button`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2.97rem;
  background-color: #8f5f34;
  color: white;
  font-size: 1.13rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  z-index: 100;
`;

const Wrapper = styled.div`
  min-height: 100vh;
  ${(props) => props.fixBottom && 'padding-bottom: 5rem'}
`;

function MyDetail() {
  let { id } = useParams();
  let [booking] = useBooking(id);
  let auth = useAuth();

  return (
    <Wrapper fixBottom={booking.status === 'checked'}>
      <Detail booking={booking} />
      {booking.status === 'checked' && auth.user && (
        <Link to="/comments">
          <BtnBottom>参观留言</BtnBottom>
        </Link>
      )}
    </Wrapper>
  );
}

function AdminDetail(params) {
  let { id } = useParams();
  let [booking, setBooking] = useBooking(id);
  let auth = useAuth();

  const updateBookingStatus = (flag) => {
    Toast.loading('请稍等');
    api
      .updateBooking(id, {
        status: flag ? 'verified' : 'rejected',
      })
      .then((res) => {
        Toast.success('操作成功');
        setBooking(res);
      });
  };

  const verify = () => {
    Modal.alert('提示', '你确定要通过审核吗?', [
      {
        text: '拒绝',
        onPress: () => {
          updateBookingStatus(false);
        },
      },
      {
        text: '通过',
        onPress: () => {
          updateBookingStatus(true);
        },
      },
    ]);
  };

  const check = () => {
    Modal.alert('提示', '你确定要通过通过检票吗?', [
      { text: '取消' },
      {
        text: '通过',
        onPress: () => {
          Toast.loading('请稍等');
          api.updateBooking(id, { status: 'checked' }).then((res) => {
            Toast.success('操作成功');
            setBooking(res);
          });
        },
      },
    ]);
  };

  return (
    <Wrapper fixBottom={booking.status === 'pending' || booking.status === 'verified'}>
      <Detail booking={booking} />
      {booking.status === 'pending' && auth.user && auth.user.role === 'admin' && (
        <BtnBottom onClick={verify}>审核</BtnBottom>
      )}
      {booking.status === 'verified' && auth.user && auth.user.role === 'admin' && (
        <BtnBottom onClick={check}>确认检票</BtnBottom>
      )}
    </Wrapper>
  );
}

export default MyDetail;
export { MyDetail, AdminDetail };
